// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-jsx": () => import("./../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-blog-jsx": () => import("./../src/pages/blog.jsx" /* webpackChunkName: "component---src-pages-blog-jsx" */),
  "component---src-pages-cart-jsx": () => import("./../src/pages/cart.jsx" /* webpackChunkName: "component---src-pages-cart-jsx" */),
  "component---src-pages-character-design-jsx": () => import("./../src/pages/character-design.jsx" /* webpackChunkName: "component---src-pages-character-design-jsx" */),
  "component---src-pages-contact-jsx": () => import("./../src/pages/contact.jsx" /* webpackChunkName: "component---src-pages-contact-jsx" */),
  "component---src-pages-faq-jsx": () => import("./../src/pages/faq.jsx" /* webpackChunkName: "component---src-pages-faq-jsx" */),
  "component---src-pages-illustrations-jsx": () => import("./../src/pages/illustrations.jsx" /* webpackChunkName: "component---src-pages-illustrations-jsx" */),
  "component---src-pages-index-jsx": () => import("./../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-legal-jsx": () => import("./../src/pages/legal.jsx" /* webpackChunkName: "component---src-pages-legal-jsx" */),
  "component---src-pages-patterns-jsx": () => import("./../src/pages/patterns.jsx" /* webpackChunkName: "component---src-pages-patterns-jsx" */),
  "component---src-pages-shop-jsx": () => import("./../src/pages/shop.jsx" /* webpackChunkName: "component---src-pages-shop-jsx" */),
  "component---src-pages-success-jsx": () => import("./../src/pages/success.jsx" /* webpackChunkName: "component---src-pages-success-jsx" */),
  "component---src-templates-blog-post-jsx": () => import("./../src/templates/blog-post.jsx" /* webpackChunkName: "component---src-templates-blog-post-jsx" */),
  "component---src-templates-pattern-post-jsx": () => import("./../src/templates/pattern-post.jsx" /* webpackChunkName: "component---src-templates-pattern-post-jsx" */),
  "component---src-templates-product-post-jsx": () => import("./../src/templates/product-post.jsx" /* webpackChunkName: "component---src-templates-product-post-jsx" */)
}

