import React, { useState } from 'react';


// check whether cookie "CookieConsentSG" is saved so we do not ask again
// we have to check first if window exists otherwise it will not build

let cookiesAccepted = false;
if (typeof window !== 'undefined') {
  if (document.cookie.split(';').some((item) => item.trim().startsWith('CookieConsentSG=true'))) {
    cookiesAccepted = true;
  } else {
    cookiesAccepted = false;
  }
}

// language
const defaultLanguage = 'en';
const defaultMenu = false;
const defaultState = {
  cookieConsent: cookiesAccepted,
  locale: defaultLanguage,
  isMenuOpen: defaultMenu,
  mobileBreakpoint: 900,
  animationCartStatus: false,
};


export const SiteContext = React.createContext(defaultState);

const Provider = (props) => {
  const [state, setState] = useState(defaultState);

  // eslint-disable-next-line no-console
  console.log('%c CONTEXT STATE:', 'background: green; color: white; display: block;', state);

  return (
    <SiteContext.Provider value={{
      data: state,
      acceptCookies: () => {
        setState({ ...state, cookieConsent: true });
      },
      dismissCookies: () => {
        setState({ ...state, cookieConsent: false });
      },
      switchLanguagePL: () => {
        setState({ ...state, locale: 'pl' });
      },
      switchLanguageEN: () => {
        setState({ ...state, locale: 'en' });
      },
      menuToggle: () => {
        setState({ ...state, isMenuOpen: !state.isMenuOpen });
      },
      addCartAnimationClass: () => {
        setState({ ...state, animationCartStatus: true });
      },
      removeCartAnimationClass: () => {
        setState({ ...state, animationCartStatus: false });
      },
    }}
    >
      {props.children}
    </SiteContext.Provider>
  );
};

export default ({ element }) => (
  <Provider>
    {element}
  </Provider>
);
