module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-netlify-cms/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-intl/gatsby-browser.js'),
      options: {"plugins":[],"path":"/opt/build/repo/src/intl","languages":["en","pl"],"defaultLanguage":"en","redirect":false},
    },{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":1320,"wrapperStyle":"margin: 20px 0"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Sugarly Designs","short_name":"Sugarly","description":"The application does cool things and makes your life better.","lang":"en","display":"standalone","icon":"src/assets/favicon/sugarly-favicon.png","start_url":"/","background_color":"#fff","theme_color":"#fff","localize":[{"start_url":"/pl/","lang":"pl","name":"Sugarly Designs","short_name":"Sugarly","description":"Description in Polish."}]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
